import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import '../App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
const TLayout = React.lazy(() => import('../containers/TLayout'));

class TApp extends Component {
    render() {
      return (
        <HashRouter>
            <React.Suspense fallback={loading()}>
              <Switch>                
                <Route path="/" name="Home" render={props => <TLayout {...props}/>} />
              </Switch>
            </React.Suspense>
        </HashRouter>
      );
    }
  }
  
export default TApp;
  